import { Card, Stack } from "react-bootstrap";
import { FcAlarmClock } from "react-icons/fc";
import { FloatingLettersTextBuilder } from 'react-animated-text-builders'
import RenderTextureCube from 'pages/graphics/render-texture/render-texture';
import { MdEmail } from "react-icons/md";

export const PerpixlCard = () => {

    return (
        <>
            <Card key='secondary' bg='dark' className="bg-opacity-100"
                text={'white'}>

                <Card.Body>

                    <RenderTextureCube />
                    <Card.Title>
                        <div className="text-center">
                            <FloatingLettersTextBuilder
                                floatingSpeed={500}
                                lettersAppearanceDelay={100}
                                animationMaxMargin={"1px"}
                                animationMinMargin={"0px"}
                            > Perpixl Rendering Technologies </FloatingLettersTextBuilder>


                        </div></Card.Title>
                    <Card.Subtitle><hr></hr></Card.Subtitle>
                    <Card.Text>
                        <div className="text-center">Established in 2019, we're a software development company driven by a real passion for the information technologies. Based in Pune, India we produce innovative and user-friendly websites, web applications, Android/IOS mobile applications. Please view our portfolio, meet the team to know more about us.
                        </div>
                    </Card.Text>
                    <Stack>
                        <div className="text-center">
                            <a href="/contact-us"> <FcAlarmClock /> Make an enquiry</a>
                        </div>
                        <div className="text-center">
                            <label><MdEmail /> support@perpixl.com</label>
                        </div>
                    </Stack>

                </Card.Body>
            </Card>
        </>
    );
}

