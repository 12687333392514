import * as THREE from 'three'
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import React, { Suspense, useState, useRef } from "react";
import { ContactShadows, Html, OrbitControls, Text, TransformControls, useCursor, useGLTF } from "@react-three/drei";
import { proxy, useSnapshot } from "valtio";

// Reactive state model using valtio
const modes = ['translate', 'rotate', 'scale']
const state = proxy({ current: null, mode: 0 })

const Model = ({ name, ...props }) => {
    // Ties this component to the state model
    const snap = useSnapshot(state);
    // Fetching the GLTF, nodes is a collection of all the meshes
    // It's cached/memoized, it only gets loaded and parsed once
    const { nodes } = useGLTF('/compressed.glb');
    // Feed hover state into setCursor, which sets document.body.style.cursor to pointer|auto
    const [hovered, setHovered] = useState(false);
    useCursor(hovered);
    return (
        <mesh
            onClick={(e) => (e.stopPropagation(), (state.current = name))}
            onPointerMissed={(e) => e.type === 'click' && (state.current = null)}
            onContextMenu={(e) => snap.current === name && (e.stopPropagation(), (state.mode = (snap.mode + 1) % modes.length))}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
            onPointerOut={(e) => setHovered(false)}
            name={name}
            // @ts-ignore
            geometry={nodes[name].geometry}
            // @ts-ignore
            material={nodes[name].material}
            material-color={snap.current === name ? '#ff6080' : 'white'}
            {...props}
            dispose={null}
        ></mesh>
    );
}


export const ModelV2 = ({ url, ...props }) => {
    const group = useRef<any>()
    // @ts-ignore
    const { nodes, materials } = useGLTF(url)
    useFrame((state) => {
        group.current.children.forEach((child, index) => {
            child.position.y += Math.sin(index * 1000 + state.clock.elapsedTime) / 50
            child.rotation.x += (Math.sin(index * 1000 + state.clock.elapsedTime) * Math.PI) / 2000
            child.rotation.y += (Math.cos(index * 1000 + state.clock.elapsedTime) * Math.PI) / 3000
            child.rotation.z += (Math.sin(index * 1000 + state.clock.elapsedTime) * Math.PI) / 4000
        })
    })
    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                material={materials.M_Curly}
                geometry={nodes.Curly.geometry}
                position={[0.8, -10.96, 2.16]}
                rotation={[1.76, 0.07, -0.19]}
            />
            <mesh
                material={materials.M_DNA}
                geometry={nodes.DNA.geometry}
                position={[19.9, -12.6, -17.01]}
                rotation={[1.26, 0.91, -1.86]}
            />
            <mesh
                material={materials.M_Headphone}
                geometry={nodes.Headphones.geometry}
                position={[20.22, 1.99, 4.03]}
                rotation={[1.55, 0.32, -0.76]}
            />
            <mesh
                material={materials.M_Notebook}
                geometry={nodes.Notebook.geometry}
                position={[-21.4, -14.96, -13.21]}
                rotation={[1.83, -0.23, 0.91]}
            />
            <mesh
                material={materials.M_Rocket}
                geometry={nodes.Rocket003.geometry}
                position={[17.58, 15.26, -25.21]}
                rotation={[1.14, 0.81, 0.44]}
            />
            <mesh
                material={materials.M_Roundcube}
                geometry={nodes.Roundcube001.geometry}
                position={[-21.17, -4.1, -12.07]}
                rotation={[1.55, 0.05, 0.45]}
                scale={[0.5, 0.5, 0.5]}
            />
            <mesh
                material={materials.M_Table}
                geometry={nodes.Table.geometry}
                position={[0.59, -3.79, -27.84]}
                rotation={[0.98, 0.15, -1.24]}
                scale={[0.5, 0.5, 0.5]}
            />
            <mesh
                material={materials.M_Headset}
                geometry={nodes.VR_Headset.geometry}
                position={[6.92, -15.17, 27.59]}
                rotation={[1.29, 0.08, -0.64]}
                scale={[5, 5, 5]}
            />
            <mesh
                material={materials.M_Zeppelin}
                geometry={nodes.Zeppelin.geometry}
                position={[-23.64, -17.96, 17.51]}
                rotation={[2.72, -0.8, 2.71]}
                scale={[0, 0, 0]}
            />
        </group>
    )
}


const Controls = () => {
    // Get notified on changes to state
    const snap = useSnapshot(state)
    const scene = useThree((state) => state.scene)
    return (
        <>
            {/* As of drei@7.13 transform-controls can refer to the target by children, or the object prop */}
            {snap.current && <TransformControls object={scene.getObjectByName(snap.current)}
                // @ts-ignore
                mode={modes[snap.mode]} />}
            {/* makeDefault makes the controls known to r3f, now transform-controls can auto-disable them when active */}
            <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} enableZoom={false} />
        </>
    )
}

const Rig = ({ children }) => {
    const ref = useRef<any>();
    useFrame((state) => {
        ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, (state.mouse.x * Math.PI) / 20, 0.05)
        ref.current.rotation.x = THREE.MathUtils.lerp(ref.current.rotation.x, (state.mouse.y * Math.PI) / 20, 0.05)
    })
    return <group ref={ref}>{children}</group>
}

export const FrontScreenGadgets = () => {
    return (
        <>
            <Canvas
                camera={{ position: [0, -10, 80], fov: 50 }} dpr={[1, 2]}
            >
                <pointLight position={[100, 100, 100]} intensity={0.8} />
                <hemisphereLight color="white" groundColor="#b9b9b9" position={[-7, 25, 13]} intensity={0.85} />
                <Suspense fallback={null}>

                    <group position={[0, 10, 0]}>

                        <Rig>
                            <ModelV2 url="/compressed.glb" />
                        </Rig>
                        <ContactShadows rotation-x={Math.PI / 2}
                            position={[0, -35, 0]}
                            opacity={0.25}
                            width={200} height={200} blur={2} far={50} />

                        <Html style={{
                            textDecoration: 'underline'
                        }} className="content" position={[0, -30, 0]} scale={[4, 4, 1]} transform>

                            <h2>We build original, dynamic and innovative softwares, web applications, mobile (android/iOS) applications, etc.
                                <br />
                                By delivering a choice in solutions, building a foundation of trust and unlocking a world of new possibilities.
                            </h2>

                        </Html>

                    </group>

                </Suspense>

                <Controls />
            </Canvas>

        </>
    );
}