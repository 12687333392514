import { PerpixlCard } from "common/Cards";
import { PageDivider } from "common/Dividers";
import { useState } from "react";
import { Col, Nav, Row, Stack } from "react-bootstrap";
import { AboutDetail } from "./pages/aboutDetail";
import { Careers } from "./pages/careers";
import { FrequentlyAskedQuestions } from "./pages/frequentlyAskedQuestions";
import { MeetOurTeam } from "./pages/meetOurTeam";
import { OurApproach } from "./pages/ourApproach";

export const AboutUs = () => {
    const [clickedButtonIndex, setClickedButtonIndex] = useState<number>(1);
    const handleClick = (index: number) => {
        setClickedButtonIndex(index);
    }
    return (
        <>
            <Stack>
                <div className="bg-light border">
                    <Row>
                        <Col sm={4} className="bg-light">
                            <PerpixlCard />
                        </Col>
                        <Col sm={8}>
                            <PageDivider startingFrom={4} title="About Us"></PageDivider>
                            <Row>
                                <Col sm={8} className="bg-light">
                                    {clickedButtonIndex == 1 && <MeetOurTeam />}
                                    {clickedButtonIndex == 2 && <AboutDetail />}
                                    {clickedButtonIndex == 3 && <Careers />}
                                    {clickedButtonIndex == 4 && <OurApproach />}
                                    {clickedButtonIndex == 5 && <FrequentlyAskedQuestions />}
                                </Col>
                                <Col>
                                    <div className="d-grid gap-2">
                                        <br />
                                        <Nav variant="pills" className="flex-column" onSelect={(selectKey) => handleClick(Number(selectKey))}>

                                            <Nav.Item>
                                                <Nav.Link eventKey="1">Meet Our Team</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="2">About Perpixl</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="3">Careers</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="4">Our Approach</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="5">FAQs</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Stack>
        </>
    );
};

export default AboutUs;