import { Col, Row } from "react-bootstrap";

export const Careers = () => {
    return (
        <>
            <Row>
                <Col sm={12}><h3>Careers</h3></Col>
                <Col sm={12}>
                    <p>Let's make the world better by joining our team.</p>
                    <p>Please drop a mail to tech@perpixl.com along with your resume and our recruitment team will get in touch with you if we find your profile suitable for our company.</p>

                </Col>
            </Row>
        </>
    );
}