import { PerpixlCard } from "common/Cards";
import { PageDivider } from "common/Dividers";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactFormViaSmtp = () => {
    const form = useRef();
    const [toSend, setToSend] = useState({
        from_name: '',
        from_email: '',
        to_name: 'Perpixl Team',
        message_caption: '',
        message: '',
        reply_to: '',
        budget: ''
    });

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const sendMail = (e) => {
        e.preventDefault();
        console.log('>>' + toSend.message_caption + process.env.REACT_APP_EMAILJS_SERVICE_ID);

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID, // SERVICE ID
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // TEMPLATE ID
            form.current, // form
            process.env.REACT_APP_EMAILJS_ACCOUNT_PUBLIC_KEY // PUBLIC KEY
        ).then((result) => {
            // Show success message
            alert('Thank you for contacting us. Our support team will analyse your message closely and get in touch with you.');
        }, (error) => {
            // show error message
            alert("We can't process your request right now due to some technical difficulties. Please mail us on support@perpixl.com and our technical team will get in contact with you." + error);
        });
    }
    return (
        <Form ref={form} onSubmit={sendMail}>
            <Form.Group>
                <Form.FloatingLabel className="mb-3" controlId="formBasicName" label="Name">
                    <Form.Control type="text" name="from_name" value={toSend.from_name} onChange={handleChange} placeholder="Enter your full name"></Form.Control>
                </Form.FloatingLabel>
            </Form.Group>

            <Form.Group>
                <Form.FloatingLabel className="mb-3" controlId="formBasicEmail" label="Email address">
                    <Form.Control type="email" name="from_email" value={toSend.from_email} onChange={handleChange} placeholder="name@example.com"></Form.Control>
                </Form.FloatingLabel>
            </Form.Group>

            <Form.Group>
                <Form.FloatingLabel className="mb-3" controlId="formBasicLookingFor" label="I am looking for">
                    <Form.Select onChange={handleChange} name="message_caption">
                        <option value="N/A">Open this select menu</option>
                        <option value="Standalone Software/Application">Standalone Software/Application</option>
                        <option value="Web Application">Web Application</option>
                        <option value="Mobile Application">Mobile Application</option>
                        <option value="IT Consulting">IT Consulting</option>
                        <option value="IT Support">IT Support</option>
                        <option value="Something else">Something else</option>
                        <option value="I'm not sure, can you advise?">I'm not sure, can you advise?</option>
                    </Form.Select>
                </Form.FloatingLabel>
            </Form.Group>

            <Form.Group>
                <Form.FloatingLabel className="mb-3" controlId="formBasicFurtherDetails" label="Further details">
                    <Form.Control as="textarea" name="message" value={toSend.message} onChange={handleChange} style={{ height: '200px' }} placeholder="Enter further details"></Form.Control>
                </Form.FloatingLabel>
            </Form.Group>

            <Form.Group>
                <Form.FloatingLabel className="mb-3" controlId="formBasicRange" label="I have a budget around">
                    <Form.Control type="text" name="budget" value={toSend.budget} onChange={handleChange} placeholder="I have a budget around"></Form.Control>
                </Form.FloatingLabel>
            </Form.Group>

            <div className="text-center">
                {/**@ts-ignore */}
                <Button type="submit">Submit</Button>
            </div>
        </Form>
    );
}

const ContactUsViaHtmlEmailRef = () => {
    return (<>
        <a href="mailto:support@perpixl.com">Click to Send an Email</a>
    </>);
}

const ContactWidget = () => {
    const smtpConfigured = true;

    if (smtpConfigured) {
        return <ContactFormViaSmtp />;
    } else {
        return <ContactUsViaHtmlEmailRef />
    }
}

const ContactUs = () => {

    return (
        <>
            <Stack>
                <div className="bg-light border">
                    <Row>
                        <Col>
                            <PerpixlCard />
                        </Col>
                        <Col sm={8}>
                            <PageDivider startingFrom={4} title="Contact Form"></PageDivider>
                            <h3>Tell Us About Your Requirement</h3>
                            <ContactWidget />
                        </Col>
                    </Row>
                </div>
            </Stack>
        </>
    );
};

export default ContactUs;