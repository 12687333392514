import { PerpixlCard } from "common/Cards";
import { Card, Col, Row } from "react-bootstrap";
import { BlinkingCursorTextBuilder } from 'react-animated-text-builders'
import { FrontScreenGadgets } from "pages/graphics/responsive-gadgets";

export const Focus = () => {
    return (
        <>
            <Row className="vh-100" style={{
                background: "linear-gradient(155deg, #ffffff 20%, #a5a3a3)",
                fontFamily: "'Inter var', sans-serif",
                color: "#101015"
            }}>
                <Col sm={4} md={4} lg={4} className="text-center">
                    <br />
                    <PerpixlCard />
                </Col>
                <Col sm={8} md={8} lg={8}>

                    <FrontScreenGadgets />

                </Col>

            </Row>
        </>
    );
}