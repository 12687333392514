import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react';
import Home from './pages/home/Home'
import Portfolio from './pages/portfolio/Portfolio';
import Services from './pages/services/Services';
import Blog from './pages/blog/Blog';
import AboutUs from './pages/about/AboutUs';
import ContactUs from './pages/contact/ContactUs';
import { Route, Routes } from 'react-router-dom'
import MainMenu, { MenuItems } from './common/Navbar';

export const App = () => {

  const SetRoutes = () => {
    return (
      <>
        <Routes>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/portfolio' element={<Portfolio />}></Route>
          <Route path='/services' element={<Services />}></Route>
          <Route path='/blog' element={<Blog />}></Route>
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route path='/contact-us' element={<ContactUs />}></Route>

          <Route path='/' element={<Home />}></Route>
        </Routes>
      </>
    )
  }

  const [navItem, setNavItem] = React.useState<string>("");
  const handleNavigation = (ni) => {
    console.log('>>nh ' + ni);
    setNavItem(ni);
  }

  React.useEffect(() => {
    console.log('>>> item ' + navItem);
    setNavItem(navItem);
  }, [navItem]);

  return (
    <>
      {console.log('>content ' + (navItem.toString() === MenuItems.PORTFOLIO.toString())
        + ' ni ' + MenuItems[navItem] + ' mi ' + MenuItems.PORTFOLIO)}
      <div className="App">
        <MainMenu handleNavigation={handleNavigation}></MainMenu>

        {MenuItems[navItem] === MenuItems.HOME && <Home />}
        {MenuItems[navItem] === MenuItems.PORTFOLIO && <Portfolio />}
        {MenuItems[navItem] === MenuItems.SERVICES && <Services />}
        {MenuItems[navItem] === MenuItems.ABOUTUS && <AboutUs />}
        {MenuItems[navItem] === MenuItems.BLOG && <Blog />}
        {MenuItems[navItem] === MenuItems.CONTACT && <ContactUs />}
        {navItem === "" && <SetRoutes />}
      </div>

    </>
  );
}

export default App;
