import { Canvas, extend, useFrame, useLoader, useThree } from '@react-three/fiber';
import { Suspense, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three'

import { MeshLineGeometry, MeshLineMaterial } from 'meshline'
import { Html, Plane } from '@react-three/drei';
import { getPerpixlEstablishmentYearsCount } from 'common/Utils';
import { AiFillDownCircle } from 'react-icons/ai';


extend({ MeshLineGeometry, MeshLineMaterial })


const Fatline = ({ curve, width, color, speed }) => {
    const ref = useRef()
    // @ts-ignore
    useFrame(() => (ref.current.material.uniforms.dashOffset.value -= speed))
    return (
        <mesh ref={ref}>
            {/**@ts-ignore */}
            <meshLineGeometry points={curve} />
            {/**@ts-ignore */}
            <meshLineMaterial transparent depthTest={false} lineWidth={width} color={color} dashArray={0.1} dashRatio={0.9} toneMapped={false} />
        </mesh>
    )
}


const Lines = ({ count, colors }) => {

    const lines = useMemo(
        () =>
            // @ts-ignore
            new Array(count).fill().map(() => {
                const pos = new THREE.Vector3(10 - Math.random() * 20, 10 - Math.random() * 20, 10 - Math.random() * 20)
                // @ts-ignore
                const points = new Array(30).fill().map(() => pos.add(new THREE.Vector3(4 - Math.random() * 8, 4 - Math.random() * 8, 2 - Math.random() * 4)).clone())
                const curve = new THREE.CatmullRomCurve3(points).getPoints(1000)
                return {
                    // @ts-ignore
                    color: colors[parseInt(colors.length * Math.random())],
                    width: Math.max(0.1, 0.5 * Math.random()),
                    speed: Math.max(0.0001, 0.0005 * Math.random()),
                    curve: curve.flatMap((point) => point.toArray())
                }
            }),
        [colors, count]
    )
    return lines.map((props, index) => <Fatline key={index} {...props} />)
}


const Rig = ({ mouse }) => {
    const { camera, viewport } = useThree()
    useFrame((state) => {
        camera.position.x += (state.mouse.x * viewport.width - camera.position.x) * 0.05
        camera.position.y += (-state.mouse.y * viewport.height - camera.position.y) * 0.05
        camera.lookAt(0, 0, 0)
    })
    return null
}


export const Confetti = () => {

    return (
        <>
            <Canvas
                camera={{ position: [0, 0, 10], fov: 25 }}
            >
                {/* @ts-ignore*/}
                <Lines count={100} colors={['#A2CCB6', '#FCEEB5', '#EE786E', '#e0feff', 'lightpink', 'lightblue']} />
                {/* <Rig /> */}

                <group>
                    <Html style={{ background: '#e0feff', opacity: '90%' }}
                        className="content" position={[0, 2, 0]} scale={[0.2, 0.3, 1]} transform>
                        <h1>About Us</h1>
                    </Html>
                    <Html style={{ background: 'pink', opacity: '90%' }}
                        className="content" position={[0, 1.5, 0]} scale={[0.2, 0.3, 1]} transform>
                        <h4> We're a highly skilled and motivated team of creative people who have been designing,
                            developing, maintaining and hosting web applications, softwares for more than {getPerpixlEstablishmentYearsCount()} years.</h4>
                    </Html>

                    <Html style={{ background: 'lightblue', opacity: '90%' }}
                        className="content" position={[0, 0.7, 0]} scale={[0.2, 0.3, 1]} transform>
                        <h4>Beginning life as a start-up business providing services in India,
                            a passion for design and the development has helped us develop our company into
                            a successful IT company based on the strength of our work and the professional
                            way we engage with our clients.
                        </h4>
                    </Html>

                    <Html style={{ background: 'lightblue', opacity: '90%' }}
                        className="content" position={[0, 0, 0]} scale={[0.2, 0.3, 1]} transform >
                        <h4> We're proud of the reputation we've built and the relationships we've developed
                            with our customers, some of which have been with us for over {getPerpixlEstablishmentYearsCount()} years,
                            and they see us as an integral part of how their business.
                        </h4>
                    </Html>
                    <Html style={{ background: '#EE786E', opacity: '90%' }}
                        className="content" position={[0, -0.9, 0]} scale={[0.2, 0.3, 1]} transform>
                        <h4> Tell us your business need And our team will map it into the world of information technology
                            and will delivery a quality product as per your need.</h4>
                        <h5><b>Check out below list of services we provide to our clients</b></h5>
                    </Html>
                </group>
            </Canvas>
        </>
    );
}