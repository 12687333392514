import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { enumKeys } from './Utils';
import RenderTextureCube from 'pages/graphics/render-texture/render-texture';

export enum MenuItems {
    HOME = "/home",
    PORTFOLIO = "/portfolio",
    SERVICES = "/services",
    BLOG = "/blog",
    ABOUTUS = "/about-us",
    CONTACT = "/contact"
}

const MainMenu = (props: {
    handleNavigation: (navItem: string) => void
}) => {
    const handleNavigation = props.handleNavigation;
    const [activationKey, setActivationKey] = React.useState<string>();

    const handleMenuNavigation = (navKey: string) => {
        console.log('>' + navKey);
        setActivationKey(navKey);
        handleNavigation(navKey);
    }

    React.useEffect(() => { }, [activationKey]);
    return (
        <Navbar bg='dark' expand='xl' variant='dark'>
            <Container>
                <Navbar.Brand href='/'>
                    <div style={{ width: 60, height: 30 }}>
                        <RenderTextureCube />
                    </div>
                    Perpixl
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav'></Navbar.Toggle>
                <Navbar.Collapse id='responsive-navbar-nav' >
                    <Nav justify variant='pills' className='container-fluid' defaultActiveKey='/' onSelect={(actKey) => handleMenuNavigation(actKey)}>
                        {enumKeys(MenuItems).map((item) => (
                            <><Nav.Item><Nav.Link eventKey={item} id={item}>{item}</Nav.Link></Nav.Item></>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
};

export default MainMenu;