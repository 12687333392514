import React from 'react'
import { PerpixlCard } from "common/Cards";
import { enumKeys } from "common/Utils";
import { useState } from "react";
import { Accordion, Badge, Card, Col, Nav, Row, Stack } from "react-bootstrap";
import { BollywoodSongsNotationsDemos } from "./bollywood-songs-notations/demos";
import { DirectX11Demos } from "./directX11/demos";
import { FluteDemos } from "./flute/demos";
import { HindustaniClassicalMusicDemos } from "./hcmusic/demos";
import { LinuxDemos } from "./linux/demos";
import { MacOSDemos } from "./macos/demos";
import { OpenGLDemos } from "./opengl/demos";
import { OpenGLESDemos } from "./opengles/demos";
import { VulkanDemos } from "./vulkan/demos";
import { WebGLDemos } from "./webgl/demos";
import { WindowsDemos } from "./windows/demos";
import { GroundReflectionsAndVideoTextures } from 'pages/graphics/groundreflections-videotextures/ground-reflections-video-textures';
import InstancedColoredCube from 'pages/graphics/instaced-vertext-colors/instanced-vertex-colors';

export enum WorkAreaType {
    ANIMATION_AND_GRAPHICS = "Animation and Graphics",
    MUSIC = "Hindustani Classical Music"
}

export enum AnimationAndGraphicsType {
    webgl = "WebGL",
    vulkan = "Vulkan",
    opengl = "OpenGL",
    opengles = "OpenGLES",

    directx11 = "DirectX 11",
    windows = "Windows",
    macOS = "MacOS",
    linux = "Linux"
}

export enum HindustaniClassicalMusicType {
    hindustaniCalssicalMusic = "Hinduastani Classical Music",
    flute = "Flute",
    bollywoodSongNotations = "Bollywood Song Notations"

}

const Blog = () => {
    const [portfolioName, setPortfolioName] = useState<string>(undefined);
    const [showcaseName, setShowcaseName] = useState<string>("");
    const [showFlashCard, setShowFlashCard] = useState<boolean>(true);
    const [showTags, setShowTags] = useState<boolean>(true);
    const [demoList, setDemoList] = useState<string[]>([]);

    // active demo index of a particular type - e.g. WebGL
    const [activeDemoIndex, setActiveDemoIndex] = useState<number | undefined>(undefined);

    const setDemos = (demos: string[]) => {
        console.log('blog setting demos ' + demos);
        setDemoList(demos);
    }

    React.useEffect(() => { }, [demoList]);

    const handleWorkAreaClicks = (name: string) => {
        setPortfolioName(name);
        setShowFlashCard(false);
        setShowcaseName("");
    }

    const handleShowcasesLinksClicks = (name: string) => {
        setShowcaseName(name);
        //setShowFlashCard(false);
        setShowTags(true);
    }

    const CodeVisorDetailsCard = () => {
        return (
            <>
                <Row>
                    <Col>
                        <h3>Coding Application</h3>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <Stack>
            <Row style={{
                background: "linear-gradient(155deg, #ffffff 20%, #a5a3a3)",
                fontFamily: "'Inter var', sans-serif",
                color: "#101015"
            }}>
                <Col sm={4} className="bg-light">
                    <br />
                    <PerpixlCard />
                    <br />
                </Col>
                <>
                    {showFlashCard && <><Col sm={8}><InstancedColoredCube /></Col></>}
                </>

                {/* RSM:: Display page details here*/}

                {showFlashCard ?
                    <></>
                    :
                    <>
                        <Col sm={8}>
                            <br /><br />
                            {portfolioName === "CodeVisor" && <CodeVisorDetailsCard />}
                            {/* RSM::Display details here*/}

                            {

                                WorkAreaType[portfolioName] === WorkAreaType.ANIMATION_AND_GRAPHICS && showTags &&
                                <Row>
                                    <Col sm={8}>

                                        {/** RSM:: Add contents here for graphics demos */}
                                        {showcaseName === "" && <label>This section is dedicated for Demos and tutorials</label>}
                                        {AnimationAndGraphicsType[showcaseName] === AnimationAndGraphicsType.vulkan && <VulkanDemos setDemos={setDemos} activeDemoIndex={activeDemoIndex} />}
                                        {AnimationAndGraphicsType[showcaseName] === AnimationAndGraphicsType.opengl && <OpenGLDemos setDemos={setDemos} activeDemoIndex={activeDemoIndex} />}
                                        {AnimationAndGraphicsType[showcaseName] === AnimationAndGraphicsType.opengles && <OpenGLESDemos setDemos={setDemos} activeDemoIndex={activeDemoIndex} />}
                                        {AnimationAndGraphicsType[showcaseName] === AnimationAndGraphicsType.webgl && <WebGLDemos setDemos={setDemos} activeDemoIndex={activeDemoIndex} />}
                                        {AnimationAndGraphicsType[showcaseName] === AnimationAndGraphicsType.directx11 && <DirectX11Demos setDemos={setDemos} activeDemoIndex={activeDemoIndex} />}
                                        {AnimationAndGraphicsType[showcaseName] === AnimationAndGraphicsType.windows && <WindowsDemos setDemos={setDemos} activeDemoIndex={activeDemoIndex} />}
                                        {AnimationAndGraphicsType[showcaseName] === AnimationAndGraphicsType.linux && <LinuxDemos setDemos={setDemos} activeDemoIndex={activeDemoIndex} />}
                                        {AnimationAndGraphicsType[showcaseName] === AnimationAndGraphicsType.macOS && <MacOSDemos setDemos={setDemos} activeDemoIndex={activeDemoIndex} />}
                                    </Col>
                                    <Col>
                                        <Nav variant="pills" className="flex-column" onSelect={(selectKey) => handleShowcasesLinksClicks(selectKey)}>
                                            {
                                                enumKeys(AnimationAndGraphicsType).map((key) => (
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={key}>{AnimationAndGraphicsType[key]}</Nav.Link>
                                                    </Nav.Item>
                                                ))
                                            }
                                        </Nav>

                                    </Col>
                                </Row>
                            }

                            {
                                WorkAreaType[portfolioName] === WorkAreaType.MUSIC && showTags &&
                                <Row>
                                    <Col sm={8}>
                                        {/** RSM:: Add contents here for hindustani music flute demos */}
                                        {showcaseName === "" && <label>This section is dedicated for flute and music</label>}
                                        {HindustaniClassicalMusicType[showcaseName] === HindustaniClassicalMusicType.bollywoodSongNotations && <BollywoodSongsNotationsDemos />}
                                        {HindustaniClassicalMusicType[showcaseName] === HindustaniClassicalMusicType.flute && <FluteDemos />}
                                        {HindustaniClassicalMusicType[showcaseName] === HindustaniClassicalMusicType.hindustaniCalssicalMusic && <HindustaniClassicalMusicDemos />}
                                    </Col>
                                    <Col>
                                        <Nav variant="pills" className="flex-column" onSelect={(selectKey) => handleShowcasesLinksClicks(selectKey)}>
                                            {
                                                enumKeys(HindustaniClassicalMusicType).map((key) => (
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={key}>{HindustaniClassicalMusicType[key]}</Nav.Link>
                                                    </Nav.Item>
                                                ))
                                            }
                                        </Nav>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </>
                }

            </Row>
            <Row>
                <Col>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><h3>Click below to see demos</h3></Accordion.Header>
                            <Accordion.Body>
                                {
                                    <Nav variant="pills" className="flex-column" onSelect={(selectKey) => handleWorkAreaClicks(selectKey)}>

                                        {
                                            enumKeys(WorkAreaType).map((key) => (
                                                <Nav.Item>
                                                    <Nav.Link eventKey={key}>{WorkAreaType[key]}</Nav.Link>
                                                </Nav.Item>
                                            ))
                                        }
                                    </Nav>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
                <Col sm={8}>


                </Col>
            </Row>
        </Stack>
    );
};

export default Blog;