import { Accordion, Col, Row } from "react-bootstrap";

export const OurApproach = () => {
    return (
        <>
            <Row>
                <Col sm={12}><h3>Our Approach</h3></Col>
                <Col sm={12}>
                    <p>During our time in business we've constantly refined and improved our approach to projects.
                        Outlined below is a brief overview of a common project life cycle:</p>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Enquiry</Accordion.Header>
                            <Accordion.Body>
                                We discuss the project with you in detail, we listen to your requirements and expectations
                                and produce a detailed project proposal, which includes an initial estimate.
                                Both parties then sign a contract which contains a detailed specification of what we will deliver
                                and the time frame of delivery.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Plan</Accordion.Header>
                            <Accordion.Body>
                                We kick-off the project with research; including customers, stakeholders and competitors'
                                sites so we fully understand the problems we're aiming to solve. After that, we work on the
                                information architecture aspects of the project, so deciding how best to organise the
                                content and label it so the site is easy to navigate for users.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Design</Accordion.Header>
                            <Accordion.Body>
                                <p>Our design phase starts by deciding on the content elements for each of the key pages along with
                                    wireframes for each of the main templates the website will contain. Wireframes allow stakeholders
                                    to focus on the content elements and their visual hierarchy without the distraction of typography,
                                    colour and texture.</p>
                                <p>Once the wireframes are approved we start work on the design of the site, translating the wireframes
                                    into finished articles, usually supplied as images.  We then revise the designs based on your
                                    feedback until final versions are agreed upon.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Build</Accordion.Header>
                            <Accordion.Body>
                                The build phase involves first translating the visual design into web standards compliant and
                                accessible HTML templates and then the development of the server-side functionality and,
                                depending on the particular project, the integration of a content management system or framework.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Test</Accordion.Header>
                            <Accordion.Body>
                                A thorough testing phase includes browser testing, user testing, accessibility testing and device testing.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Launch</Accordion.Header>
                            <Accordion.Body>
                                The champagne is on ice, which can mean only one thing, it's time to launch the website!
                                Before a project goes live it first has to pass every point on our detailed "go live"
                                checklist to ensure it's 100% ready.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Maintain</Accordion.Header>
                            <Accordion.Body>
                                <p>You've taken off but the journey's only just begun!
                                    A website is always a work-in-progress so this is the time to review, analyse and improve.</p>
                                <p>We gather and analyse data from a variety of sources including user and stakeholder feedback,
                                    Google Analytics, Google's Webmaster Tools (WMT) and server performance metrics.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </>
    );
}