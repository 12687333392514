import React from 'react';
import { FullPageDivider, PageDivider } from 'common/Dividers';
import { Focus } from './pages/focus';
import { AboutUs } from './pages/aboutUs';
import { WhatWeDo } from './pages/whatWeDo';
import { Specialism } from './pages/specialism';
import { OurWork } from './pages/ourWork';
import { GetInTouch } from './pages/getInTouch';
import { Copyright } from './pages/copyright';
import { Stack, Row } from 'react-bootstrap';
import { GroundReflectionsAndVideoTextures } from 'pages/graphics/groundreflections-videotextures/ground-reflections-video-textures';
import { InfiniteScroll } from 'pages/graphics/infinite-scrolling/infinite-scroll';
import { ReflectorPlaneAndBloomWithTriangles } from 'pages/graphics/reflectorplane-bloom/reflectorplane-bloom';
import { ChangingBackground } from 'pages/graphics/changing-background/portfolio-frontpage-changing-bg';
const { Carousel } = require("react-bootstrap")


// TODO - Carousel to be used if required later
const CarouselSlides = () => {
    return (
        <div>
            <Carousel interval={2500}>
                <Carousel.Item>
                    <img className='d-block w-100 h-60 img-fluid'
                        src="/images/startup-first.jpg"
                        alt='First Slide'
                        width="300" height="300"
                    ></img>
                    <Carousel.Caption style={{ background: 'black' }}>
                        <h3 style={{ color: 'white' }}>First Slide Label</h3>
                        <p style={{ color: 'white' }}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img className='d-block w-100 h-60 img-fluid'
                        src="/images/startup-second.jpg"
                        alt='Second Slide'
                        width="300" height="300"
                    ></img>
                    <Carousel.Caption style={{ background: 'black' }}>
                        <h3 style={{ color: 'white' }}>Second slide label</h3>
                        <p style={{ color: 'white' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </div>
    );
}


const Home = () => {
    const whatWeDoImages: string[] = ['/images/services/corporate-training-image.24891671316ff80f6c3e.png', '/images/services/WebDevelopmentImage.jpeg', '/images/services/ios-app-development.jpg',
        '/images/services/consulting-g52ae5a56f_1920.jpg', '/images/services/web-g9266f1f35_1920.jpg', '/images/services/web-g35e3d4239_1920.jpg',
        '/images/services/buildings-gce1105b5f_1280.png', '/images/services/it-outsourcing.jpg', '/images/services/android-app-development.jpg'];

    return (
        <>
            <Stack>
                <div>
                    <Row className="vh-100">
                        <GroundReflectionsAndVideoTextures />
                    </Row>
                </div>
                <div>
                    <Focus />
                </div>

                <div className="vh-100">
                    <AboutUs />
                </div>
                <div className="bg-light bg-opacity-80">
                    <Row className="vh-100">
                        <InfiniteScroll images={whatWeDoImages} label={"Our Services"} repeat={false} />
                    </Row>
                </div>
                <div className="bg-light">
                    <Row className='vh-100'>
                        <ChangingBackground />
                    </Row>
                </div>
                <div className="bg-white">
                    {/**@ts-ignore */}
                    <PageDivider title="Get In Touch" />
                    <br /><br /><br /><br />
                    <GetInTouch />
                    {/**@ts-ignore */}
                    <PageDivider title="" />
                </div>

                <div className="bg-dark bg-opacity-80 text-center text-white">
                    <Copyright />
                </div>
            </Stack>
        </>
    );
};

export default Home;