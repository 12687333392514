import { getPerpixlEstablishmentYearsCount } from "common/Utils";
import { Card, Col, Row } from "react-bootstrap";
import { AiFillDownCircle } from "react-icons/ai";
import { BlinkingCursorTextBuilder } from 'react-animated-text-builders'
import { ReflectorPlaneAndBloomWithTriangles } from "pages/graphics/reflectorplane-bloom/reflectorplane-bloom";
import { Confetti } from "pages/graphics/confetti/confetti";

export const AboutUs = () => {
    return (
        <>
            <Row className="h-100">
                <Confetti />
            </Row>

        </>
    );
}