
export const OpenGLESDemos = (props: {
    setDemos: (demos: string[]) => void,
    activeDemoIndex: number
}) => {
    return (
        <>
            OpenGLES Demos
        </>
    );
}