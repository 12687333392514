
export const LinuxDemos = (props: {
    setDemos: (demos: string[]) => void,
    activeDemoIndex: number
}) => {
    return (
        <>
            Linux Demos
        </>
    );
}