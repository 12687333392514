
export const OpenGLDemos = (props: {
    setDemos: (demos: string[]) => void,
    activeDemoIndex: number
}) => {
    return (
        <>
            OpenGL Demos
        </>
    );
}