import { Row } from "react-bootstrap";
import { BirdsWithTextAnimation } from "pages/graphics/bird-text-animation/bird-with-text";
import { InfiniteScroll } from "pages/graphics/infinite-scrolling/infinite-scroll";



export const Portfolio = () => {
    const whatWeDoImages: string[] = ['/images/services/corporate-training-image.24891671316ff80f6c3e.png', '/images/services/WebDevelopmentImage.jpeg', '/images/services/ios-app-development.jpg',
        '/images/services/consulting-g52ae5a56f_1920.jpg', '/images/services/web-g9266f1f35_1920.jpg', '/images/services/web-g35e3d4239_1920.jpg',
        '/images/services/buildings-gce1105b5f_1280.png', '/images/services/it-outsourcing.jpg', '/images/services/android-app-development.jpg'];
    return (
        <>
            <Row style={{
                background: "linear-gradient(155deg, #ffffff 20%, #a5a3a3)",
                fontFamily: "'Inter var', sans-serif",
                color: "#101015",
                height: '400px'
            }}>

                <BirdsWithTextAnimation />

            </Row>
            <Row className="vh-100">
                <InfiniteScroll images={whatWeDoImages} label={"We provide below servics -"} repeat={false} />
            </Row>

        </>
    );
};

export default Portfolio;