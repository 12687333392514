import { Button, Col, Row } from "react-bootstrap";

export const FullPageDivider = (props: { title: String }) => {
    return (
        <>
            <br />
            <Row>
                <Col xs={10}>

                </Col>
                <Col>
                    <div className='d-grid gap-2'>
                        <a>{props.title}</a>
                    </div>
                </Col>
            </Row>
            <br />
        </>
    );
}

export const PageDivider = (props: { title: String, startingFrom: number }) => {
    return (
        <>
            <Row className="bg-light">
                <Col>
                    <div ><hr style={{ height: '10px' }} className='border' color="#609FFC" /></div>
                </Col>
                {props.title != "" &&
                    <Col xs={4}>
                        <div className='d-grid gap-2'>
                            {/**@ts-ignore */}
                            <Button disabled>{props.title}</Button>
                        </div>
                    </Col>
                }
            </Row>
        </>
    );
}