import { Col, Row } from "react-bootstrap";

export const AboutDetail = () => {
    return (
        <>
            <Row>
                <Col sm={12}><h3>About Perpixl</h3></Col>
                <Col sm={12}>
                    <p>Based in Pune, India our services includes Software Development, Enterprise Application Development, AR/VR/XR Application Development, Website Development, Android/iOS Application Development, Web Application Development, etc</p>
                    <p>We provide a vast number of services to our clients by making trust and to make them grow their business.</p>
                    <p>I hope our expertise is helpful in shaping your business. To know more, please get in touch with our team.</p>
                </Col>
            </Row>
        </>
    );
}
