import { Accordion, Col, Row } from "react-bootstrap";

export const FrequentlyAskedQuestions = () => {
    return (
        <>
            <Row>
                <Col sm={12}><h3>Frequently asked questions</h3></Col>
                <Col sm={12}>
                    <p>Below are some frequently asked questions which might help. If anything other information
                        is required then do not hesitate to get in touch with our team.
                    </p>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How do I get quote?</Accordion.Header>
                            <Accordion.Body>
                                We cannot give you an accurate quote until we know more about your company,
                                your customers and your overall objectives, please contact us to start
                                the ball rolling.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How long does the average software development project take?</Accordion.Header>
                            <Accordion.Body>
                                <p>The majority of our software projects are completed within 12-24 weeks. Smaller utility style softwares can be completed much faster.</p>
                                <p>In our experience the main delay with web design projects is due to late delivery of website content so if
                                    you have finalised content that you can provide at the very start of the project we can often
                                    fast-track your project considerably.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What are the payment terms?</Accordion.Header>
                            <Accordion.Body>
                                <p>For the vast majority of projects we ask for a third upfront, another third at the project mid-point
                                    and the remaining third on completion.</p>
                                <p>For smaller projects we sometimes split the payments into two, one upfront and the remainder on completion.</p>
                                <p>For updates and on-going work we generally invoice clients on a monthly basis so at the end of each month
                                    you receive an invoice for our work for that month, including a breakdown of the hours and tasks
                                    with payment due within 30 days.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>How does the software development process works?</Accordion.Header>
                            <Accordion.Body>
                                Please take a look at our web design approach which shows the process in detail.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Can we view the progress of the software development as it progresses</Accordion.Header>
                            <Accordion.Body>
                                Yes, very early on in our web design and web application projects we'll provide you with
                                a password-protected location on one of our staging servers where you can view the draft
                                site as it progresses. Updates are pushed to this staging site automatically, as we work
                                on the project. So you will be able to check on progress throughout the project life cycle.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Can we have a meeting before we go ahead?</Accordion.Header>
                            <Accordion.Body>
                                Yes, we are happy to provide a free initial consultation to discuss your project in person, please contact us.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Do you help in hosting a website?</Accordion.Header>
                            <Accordion.Body>
                                Yes, we prefer to host our client's websites on our scalable cloud hosting infrastructure,
                                to find out more please read about our web hosting services.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Do you offer backend programming service?</Accordion.Header>
                            <Accordion.Body>
                                Yes, we are able to cater for all aspects of website / web application projects from design through to complex
                                database driven programming, please contact us to discuss your requirements.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Can you help us with the visual templates of what we need?</Accordion.Header>
                            <Accordion.Body>
                                Yes, we can assist with copywriting, photography and video production to either create or massage your existing
                                content into the best raw materials from which to build a website that engages your visitors and
                                meets your objectives.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Can you provide the internship to our employees/students?</Accordion.Header>
                            <Accordion.Body>
                                Yes we offer 6 months of internship to the employees/students in which we teach them how things works in the companies
                                by creating the demo applications and deploying on the servers.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </>
    );
}