import { Button, Col, Row, Stack } from "react-bootstrap";
import { BsLinkedin } from "react-icons/bs";
import { FaTwitterSquare, FaWhatsappSquare } from "react-icons/fa";
import { FcAddressBook, FcAlarmClock } from "react-icons/fc";
import { MdEmail } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";

export const GetInTouch = () => {
    return (
        <>
            <Row>
                <Col md={6}>
                    <Stack>
                        <div className="text-center">
                            {/**@ts-ignore */}
                            <Button variant="outline-primary" href="/contact-us"><FcAlarmClock /> Make an enquiry</Button>
                        </div>
                        <div className="text-center">
                            <label><FaWhatsappSquare /> +91 8087225014</label>
                        </div>
                        <div className="text-center">
                            <label><MdEmail /> support@perpixl.com</label>
                        </div>

                        <div className="text-center">
                            <label><FcAddressBook /> Perpixl Rendering Technologies Pvt. Ltd.</label>
                            <br />
                            <label>Pune, India, 411 017</label>
                        </div>

                    </Stack>

                </Col>
                <Col md={6}>
                    <Stack>
                        <div className="text-center">
                            <label><h3> <RiUserFollowLine />Follow Us On</h3></label>
                        </div>
                        <div className="text-center">
                            <Button variant="outline-primary" href="https://www.linkedin.com/company/perpixl-rendering-technologies-private-limited/" size="lg"><BsLinkedin /> LinkedIn</Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant="outline-primary" href="https://twitter.com/perpixl?s=20" size="lg"><FaTwitterSquare /> Twitter</Button>

                        </div>
                        <div className="text-center">
                        </div>
                    </Stack>
                    <br /><br /><br /><br /><br /><br />
                </Col>
            </Row>
        </>
    );
}