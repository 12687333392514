import { PerpixlCard } from "common/Cards";
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { Col, Row, Stack } from "react-bootstrap";
import { PageDivider } from "common/Dividers";
import { getPerpixlEstablishmentYearsCount } from "common/Utils";
import { SphericalWords } from "pages/graphics/spherical-words/sperical-words";

const Services = () => {

    return (

        <Stack>
            <Row style={{
                background: "linear-gradient(155deg, #ffffff 20%, #a5a3a3)",
                fontFamily: "'Inter var', sans-serif",
                color: "#101015"
            }}>
                <Col sm={4} className="bg-light">
                    <br />
                    <PerpixlCard />
                    <br />
                </Col>
                <Col sm={8}>
                    <SphericalWords />
                </Col>

            </Row>
            <Row>
                <Col>
                    <PageDivider startingFrom={4} title="What We Do"></PageDivider>

                    <Row className="bg-light">
                        <Col sm={{ span: 8 }}><h3>Software Development</h3></Col>
                        <Col sm={8}>
                            <p>We've been designing and developing quality softwares for over {getPerpixlEstablishmentYearsCount()} years and have a huge amount of hand's on experience that's ingrained in everything we do.</p>
                            <p>We're motivated by the challenges our clients bring to us and how we can work with them to create something that exceeds their expectations whilst functioning reliably and looking great.</p>
                            <p>We are not limited to specific platform. We have hand's no experience in creating softwares on various platforms like Windows, Linux, MacOS, Android, iOS, Web. You just need to tell what you need and our intelligent development team will get it done.</p>
                            <p>The most impressive thing is that you can also track the progress of the software periodically so that we can manage your expectations or any changes ongoing.</p>

                        </Col>
                        <Col sm={4} className="bg-secondary bg-opacity-10" style={{ textAlign: "left" }} >

                            <h5><BsFillArrowRightCircleFill /> Windows Application Development</h5>
                            <h5><BsFillArrowRightCircleFill /> MacOS Application Development</h5>
                            <h5><BsFillArrowRightCircleFill /> Android Application Development</h5>
                            <h5><BsFillArrowRightCircleFill /> iOS Application Development</h5>
                            <h5><BsFillArrowRightCircleFill /> Linux Application Development</h5>
                            <br />
                        </Col>
                    </Row>
                    <PageDivider startingFrom={4} title=""></PageDivider>
                    <Row className="bg-secondary bg-opacity-10">
                        <Col sm={{ span: 8 }}><h3>Web Application Development</h3></Col>
                        <Col sm={8}>
                            <p>Do you need advise on how you can increase your business by using information technology? Here is the solution: you just get in touch with our support team and they will guide you how Enterprise applications can help you boost your business.</p>
                            <p>We have a vast experience in developing enterprise applications by using multiple technologies. But we are not limited to any technology. We will figure out what technologies are best suited to achieve your goal.</p>
                            <p>Please get in touch with Our Team to know more.</p>
                        </Col>
                        <Col sm={4} className="bg-secondary bg-opacity-10" style={{ textAlign: "left" }}>
                            <h5><BsFillArrowRightCircleFill /> Enterprise Java Applications</h5>
                            <h5><BsFillArrowRightCircleFill /> .NET Applications</h5>
                            <br />
                        </Col>
                    </Row>

                    <PageDivider startingFrom={4} title=""></PageDivider>
                    <Row className="bg-secondary bg-opacity-10">
                        <Col sm={{ span: 8 }}><h3>IT Support/Software Maintenance</h3></Col>
                        <Col>
                            <p></p>
                        </Col>
                    </Row>

                    <PageDivider startingFrom={4} title=""></PageDivider>
                    <Row className="bg-secondary bg-opacity-10">
                        <Col sm={{ span: 8 }}><h3>Corporate Training</h3></Col>
                        <Col>
                            <p></p>
                        </Col>
                    </Row>

                    <PageDivider startingFrom={4} title=""></PageDivider>
                    <Row className="bg-secondary bg-opacity-10">
                        <Col sm={{ span: 8 }}><h3>IT Outsourcing</h3></Col>
                        <Col>
                            <p></p>
                        </Col>
                    </Row>

                    <PageDivider startingFrom={4} title=""></PageDivider>
                    <Row className="bg-light bg-opacity-50">
                        <Col sm={{ span: 8 }}><h3>Ar/Vr/Xr Application Development</h3></Col>
                        <Col sm={8}>
                            <p>Perpixl is a company that creates innovative and impactful XR solutions, employing emerging AR, VR and metaverse technology that push the boundaries of what is possible in XR across entertainment, sports, e-commerce and enterprise.</p>
                            <p>At Perpixl, we believe in the power of XR to tell stories and bring immersive worlds, characters, and brands to life in the metaverse. As one of the XR company, our team is a distinct group of developers, strategists, visual artists, gamers, musicians, foodies and bookworms who share a love for mixed reality. As a highly skilled and motivated team, Perpixl has made a point to work with a diverse slate of clients and partners who represent our inclusive values and mission.</p>

                        </Col>
                        <Col sm={4} className="bg-secondary bg-opacity-10" style={{ textAlign: "left" }}>

                            <h5><BsFillArrowRightCircleFill /> Unity</h5>
                            <h5><BsFillArrowRightCircleFill /> Google ARCore</h5>
                            <h5><BsFillArrowRightCircleFill /> ARKit</h5>
                            <br />
                        </Col>
                    </Row>
                    <PageDivider startingFrom={4} title=""></PageDivider>
                </Col>
            </Row>
        </Stack>
    );
};

export default Services;