import { ButtonGroup, Col, DropdownButton, Row } from "react-bootstrap";
import React from 'react';

export const WebGLDemos = (props: {
    setDemos: (demos: string[]) => void,
    activeDemoIndex: number
}
) => {


    return (
        <><h3>WebGL Demos</h3>
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <h3><a target="_self" href="/webgl/index.html">Click here to see live Demos</a></h3>
                </Col>
            </Row>
        </>
    );
}