
export const VulkanDemos = (props: {
    setDemos: (demos: string[]) => void,
    activeDemoIndex: number
}) => {


    return (
        <>
            Vulkan Demos
        </>
    );
}