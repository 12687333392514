
export const WindowsDemos = (props: {
    setDemos: (demos: string[]) => void,
    activeDemoIndex: number
}) => {

    return (
        <>
            Windows Demos
        </>
    );
}