
export const DirectX11Demos = (props: {
    setDemos: (demos: string[]) => void,
    activeDemoIndex: number
}) => {
    return (
        <>
            DirectX11 Demos
        </>
    );
}