import { Canvas, useFrame } from '@react-three/fiber';
import { useRef, useState } from 'react';
import { ContactShadows, OrbitControls, Text, RenderTexture, PerspectiveCamera } from '@react-three/drei';

function Cube() {
    const textRef = useRef()
    //@ts-ignore
    useFrame((state) => (textRef.current.position.x = Math.sin(state.clock.elapsedTime) * 4))
    return (
        <mesh>
            <boxGeometry />
            <meshStandardMaterial>
                <RenderTexture attach="map" anisotropy={16}>
                    <PerspectiveCamera makeDefault manual aspect={1 / 1} position={[0, 0, 5]} />
                    <color attach="background" args={['orange']} />
                    <ambientLight intensity={0.5} />
                    <directionalLight position={[10, 10, 5]} />
                    <Text ref={textRef} fontSize={4} color="#555">
                        Perpixl
                    </Text>
                </RenderTexture>
            </meshStandardMaterial>
        </mesh>
    )
}

function RenderTextureCube() {
    return (
        <Canvas
            camera={{ position: [2.5, 2.5, 2.5], fov: 25 }}
            style={{
                background: 'linear-gradient(155deg, #ffffff 20%, #a5a3a3)'
            }}
        >
            <ambientLight intensity={0.3} color="#FFFFFF"></ambientLight>
            <pointLight intensity={1.0} position={[2, 0, -2]}></pointLight>

            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 5]} />
            <Cube />
            <ContactShadows frames={1} position={[0, -0.5, 0]} blur={1} opacity={0.75} />
            <ContactShadows frames={1} position={[0, -0.5, 0]} blur={3} color="orange" />
            <OrbitControls enableZoom={false} minPolarAngle={0} maxPolarAngle={Math.PI / 2.1} />
        </Canvas>
    );
}

export default RenderTextureCube;
