import { Col, Row } from "react-bootstrap";

export const MeetOurTeam = () => {
    return (
        <>
            <Row>
                <Col sm={12}><h3>Meet Our Team</h3></Col>
                <Col sm={12}>
                    We’re a highly skilled and experienced team driven by a real passion for the web. We’ve been designing, developing, maintaining and hosting websites and business-critical web applications since 2000. We do web and print design, we build web and mobile apps, we do online marketing and, of course, hosting. Most importantly; we listen to you and transform your vision into a digital masterpiece that we are only completely satisfied with when you are.
                </Col>
            </Row>
        </>
    );
}
